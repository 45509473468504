// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require('jquery')
require("packs/common")
require('packs/pride_calendar')
require("datatables.net-bs4")
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")

require("packs/datetime-picker")

require("bootstrap")
require("cocoon-js")
require("parsleyjs")
require("fullcalendar")
require("jquery-validation")

// import 'bootstrap';
// import 'jquery-validation';
// import 'cocoon-js';
// import 'parsleyjs';
// import 'fullcalendar';
// import 'datatables.net-bs4';
// import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
window.jQuery = $;
window.$ = $;

function checkValue(element) {
  // check if the input has any value (if we've typed into it)
  if ($(element).val())
    $(element).addClass('has-value');
  else
    $(element).removeClass('has-value');
}

$(document).on("turbolinks:load cocoon:after-insert", function() {

  // Run on page load
  $('.form-control').each(function() {
    checkValue(this);
  })
  // Run on input exit
  $('.form-control').blur(function() {
    checkValue(this);
  });

  // attachment validations and upload
  $('.file_after_selected').hide();
  $('.remove_sign').show();
  
  $('[data-file-type]').on('change', function(){
    var filePath = $(this).val();
    var cross_sign_element = $(this).parent().find(".file_after_selected");

    // Allowed file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.odt)$/i;

    if (!allowedExtensions.exec(filePath) && filePath !== "") {
      alert('Invalid file type, only .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .odt file allowed !');
      $(this).val('');
      $(this).parent().find(".remove_sign").hide();
      cross_sign_element.removeClass("remove_sign");
      return false;
    } else {
      cross_sign_element.show();
      cross_sign_element.addClass("remove_sign");
    }
  })
  
  $('[data-file-type-image]').on('change', function(){
    var filePath = $(this).val();
    var cross_sign_element = $(this).parent().find(".file_after_selected");
    
    // Allowed file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    
    if (!allowedExtensions.exec(filePath) && filePath !== "") {
      alert('Invalid file type, only .jpg, .jpeg, .gif file allowed !');
      $(this).val('');
      $(this).parent().find(".remove_sign").hide();
      cross_sign_element.removeClass("remove_sign");
      return false;
    } else {
      cross_sign_element.show();
      cross_sign_element.addClass("remove_sign");
    }
  })

  // To remove selected file.
  $('.file_after_selected').on('click', function(){
    $(this).parent().find("input").val("");
    $(this).removeClass("remove_sign");
    $(this).hide();
  })

  // To delete associated attachment.
  $('.delete_file').on('click', function(){
    var object_id = $(this).data('id');
    var object_type = $(this).data('type');
    var url = "/"+ object_type + "/" + object_id;
    
    $.ajax({
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      url: url,
      method: 'DELETE',
      dataType: "script",
      error: function(){
        alert('Something went wrong !');
      }
    }).done(function() {
      location.reload();
    });
  });
});

$(document).ready(function(){
  $(".alert").delay(3000).slideUp(1000, function(){
    $(".alert").alert('close');
  });

  $('.remove-alert').on('click', function(){
    $(".alert").alert('close');
  });
});
