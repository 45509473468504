import 'fullcalendar'

function eventCalendar() {
  return $('#calendar').fullCalendar({
    header: {
      left: 'prev, next, today',
      center: 'title',
      right: 'month, agendaWeek, agendaDay, listWeek'
    },
    allDayDefault: false,
    selectable: false,
    selectHelper: false,
    editable: false,
    eventLimit: true,
    nowIndicator: true,
    navLinks: true,
    events: '/pride_event_registrations/pride_calendar.json',
    timeFormat: 'H(:mm)'
  });
}

function clearCalendar() {
  $('#calendar').html('');
}

$(document).on('turbolinks:load', eventCalendar);
$(document).on('turbolinks:before-cache', clearCalendar);

