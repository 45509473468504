import 'bootstrap-datetime-picker';

$(document).on("turbolinks:load cocoon:after-insert shown.bs.modal", function () {

  function calculate_duration(start_time, end_time) {
    start_time = new Date(start_time).getTime();
    end_time = new Date(end_time).getTime();
    var duration_field = document.getElementById("pride_event_duration");

    if (end_time < start_time) {
      duration_field.placeholder = "Enter valid date.";
      document.getElementById('pride_event_end_date').value = "";
      return false;
    } else {
      let difference   = (end_time - start_time) / 1000;;
      let days    = Math.floor(difference / (3600 * 24));
      let hours   = Math.floor((difference - (days * (3600 * 24)))/3600);
      let minutes = Math.floor((difference - (days * (3600 * 24)) - (hours * 3600)) / 60);

      if (hours   < 10) {hours   = "0" + hours;}
      if (minutes < 10) {minutes = "0" + minutes;}
      
      if (Number.isNaN(days) || Number.isNaN(hours) || Number.isNaN(minutes)) {
        document.getElementById('pride_event_end_date').value = "";
        duration_field.placeholder = "Enter valid date.";
        duration_field.focus();
      } else {
        duration_field.value = (days +' days, ' + hours + ' hours, ' + minutes + ' minutes');
        duration_field.focus();
      }
    }
  }

  if (window.jQuery().datetimepicker) {
    $('[data-globaltimepicker]').datetimepicker('setOptions', {
      format: 'd/m/Y',
      minView: 'month',
      endDate: new Date(),
      todayBtn: true,
      todayHighlight: true,
      timepicker: false})
    
    $('[data-datepicker]').datetimepicker({
      format: 'yyyy-mm-dd',
      minView: 'month',
      endDate: new Date(),
      todayBtn: true,
      todayHighlight: true,
      autoclose: true
    });

    $('[data-eventtimepicker]').datetimepicker({
      format: 'yyyy-mm-dd hh:ii',
      startDate: new Date(),
      todayBtn: true,
      todayHighlight: true,
      autoclose: true
    }).on("show changeDate", function(e) {
      let get_start_time = document.getElementById('pride_event_start_date').value;
      let get_end_time = document.getElementById('pride_event_end_date').value;
      let start_time = new Date(get_start_time);
      let end_time = new Date(get_end_time);
      
      $("#pride_event_end_date").datetimepicker('setStartDate', start_time);
      calculate_duration(start_time, end_time);
    });

    $('.calendar_with_time').on('click', function(){
      $(this).parent().find('[data-eventtimepicker]').datetimepicker("show");
    });

    $('.calendar_with_date, .admin_calendar_with_date').on('click', function(){
      $(this).parent().find('[data-datepicker]').datetimepicker("show");
    });
  
    $('.calendar_with_date, .admin_calendar_with_date').on('click', function(){
      $(this).parent().find('[data-globaltimepicker]').datetimepicker("show");
    });
  }
});
